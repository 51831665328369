@import "../node_modules/leaflet/dist/leaflet.css";

.leaflet-container {
  width: 100%;
  height: 80vh;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.request-popup .leaflet-popup-content-wrapper {
  border-radius: 0;
}

.leaflet-tooltip{
  width: 150px;
  white-space: normal;
}