@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

// Set your brand colors
$marine: #354F6E;
// Update Bulma's global variables
$primary: $marine;
$primary-light: #F0FFF0;
$navbar-height: 4rem;
$navbar-background-color: transparent;
$footer-color: #F5F5F5;
$footer-background-color: #354F6E;
// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";


@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
@import "../node_modules/bulma/sass/grid/tiles.sass";
@import "../node_modules/bulma/sass/elements/notification.sass";
@import "../node_modules/bulma/sass/elements/tag.sass";
@import "../node_modules/bulma/sass/helpers/_all.sass";

@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/components/media.sass";


@import "../node_modules/bulma/sass/components/card.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";

//pour que les anchors # arrivent au bon endroit
html {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
}


img.react-parallax-bgimage
{
   max-width: fit-content;
   -webkit-transition: opacity 1s ease-in-out;
   -moz-transition: opacity 1s ease-in-out;
   -o-transition: opacity 1s ease-in-out;
   transition: opacity 1s ease-in-out;
}
div.react-parallax-content
{
  height:100vh;
}
//pour être au dessus de la carte :(
.navbar.is-fixed-bottom, .navbar.is-fixed-top
{
  z-index: 2000 ;
  .navbar-brand {
    font-family: 'Oooh Baby', cursive;
    font-weight: 500;
  }
}
.footer a {
  color :#fff;

}
img.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

img.has-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

}

 .title-joli {
  font-family: 'Oooh Baby', cursive;
   font-weight: 500;
}
.navbar
{
  font-family: 'Oooh Baby', cursive;
  font-size: 1.5em;
  font-weight: 500;
  span.fas {
    margin-right:4px;
    font-size: 0.8em;

  }
}
.navbar.is-opaque
{
	background-color: #FFFFFF77;
	transition:  background-color 2s;
}
.navbar.is-primary
{
  transition:  background-color 2s;
  .navbar-item
  {
      color :#fff;


  }
  .navbar-item:hover
  {
    color :$primary;
  }
}






.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.tag i {
  padding-right: 5px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
